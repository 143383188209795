import React, { useContext, useEffect } from "react"
import { ApiContext, UserContext } from "../user_wrapper"
import { Image } from "@chakra-ui/image"

import FacebookLogin from "../facebook_login"
import continueWithFacebookButton from "../../images/continueWithFacebookButton.png"
import { FacebookLoginResponse } from "../facebook_login/facebook_login"
import { LocalStorage } from "../../services/local_storage"
import { ApiResponse } from "../../services/api"
import { UpdateAccountRequest } from "../../services/api_types"

interface FacebookLoginWrapperProps {
  // we call after login with valid token
  // only used for after effects of login
  onLogin: any
}

const FacebookLoginWrapper: React.FC<FacebookLoginWrapperProps> = props => {
  const user = useContext(UserContext)
  const apiClient = useContext(ApiContext)

  useEffect(() => {
    if (!user.data?.is_anonymous) {
      props.onLogin()
    }
  }, [])

  const handleLogin = async (res: FacebookLoginResponse) => {
    if (!user.data?.is_anonymous) {
      const hasValidTokenRes: ApiResponse<boolean> = await apiClient.hasValidToken()
      if (hasValidTokenRes) {
        props.onLogin()
      }
    }

    const apiRes: ApiResponse<string> = await apiClient.login(
      res.profile,
      res.tokenDetail
    )
    if (!!apiRes.error) {
      console.log(apiRes.error.message)
      alert("Issue logging in")
      return
    }

    let accountRes = await apiClient.getAccount()
    const updateAccountRequest: UpdateAccountRequest = {}
    if (!accountRes.data?.account?.name && !!res?.profile?.name) {
      updateAccountRequest.name = res.profile.name
    }
    if (
      !accountRes.data?.account?.profile_image_url &&
      !!res?.profile?.picture?.data?.url
    ) {
      updateAccountRequest.profile_image_url = res.profile.picture.data.url
    }

    if (Object.keys(updateAccountRequest).length > 0) {
      const res: ApiResponse<
        boolean | undefined
      > = await apiClient.updateAccount(updateAccountRequest)
      if (!res.data) {
        console.log("issue updating account")
      }
      accountRes = await apiClient.getAccount()
    }

    if (!!accountRes.error) {
      console.log("error fetching account from signup", accountRes.error)
      return
    }

    if (!!user.resetUser) {
      await user.resetUser()
    }
    props.onLogin()
  }

  const handleError = (err: any) => {
    console.log(err)
  }

  return (
    <FacebookLogin
      onLogin={(data: FacebookLoginResponse) => {
        handleLogin(data)
      }}
      onError={_ => {
        handleError(_)
      }}
    >
      {props.children}
    </FacebookLogin>
  )
}

export default FacebookLoginWrapper
